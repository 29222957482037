.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0;
  text-align: center;
}

.not-found__image {
  margin-bottom: 32px;
}

.not-found__image img {
  max-width: 100%;
  height: auto;
}

.not-found h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #59358C;
  margin-bottom: 40px;
}

.not-found p {}

@media screen and (max-width: 991px) {

  .not-found {
    padding: 60px 0;
  }

}